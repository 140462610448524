import { createStore } from 'vuex'

export default createStore({
  state: {
    score:0,
    types:[],
    numbers: "",

    currentSeq:0,
    generated:[],
    answer:"10"

  },
  mutations: {
    setScore(state, score) {
      state.score = score
    },
    setTypes(state, types) {
      state.types = [...types]
    },
    setNumbers(state, numbers) {
      state.numbers = numbers
    },

    setCurrentSeq(state, currentSeq) {
      state.currentSeq = currentSeq
    },
    setGenerated(state, generated) {
      state.generated = [...generated]
      console.log(state.generated)
    },
    setAnswer(state, answer) {
      state.answer = answer
    }

  },
  actions: {
    addScore({state, commit}, score){
      commit('setScore', state.score + score)
    }

  },
  modules: {
  }
})
