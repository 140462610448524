<template>
  <div class="footers">
    <template v-for="number of 10" :key="number">
      <!-- <div class="one-number" @click="addNumber(number-1)">{{ number-1 }}</div> -->
      <van-button class="one-number"  @click="addNumber(number-1)">
        {{ number-1 }}
      </van-button>
    </template>
  </div>
</template>

<script>
import { ref, computed, watch } from "vue";
import { useStore } from "vuex";
export default {
  name: "VFooter",
  setup() {
    const store = useStore();
    const answer = computed(() => store.state.answer);

    const addNumber = (number) => {
      store.commit("setAnswer", parseInt(answer.value + number).toString());
    };

    return {
      answer,
      addNumber,
    };
  },
};
</script>

<style  scoped>
.footers {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  font-size: 3rem;
}
.one-number {
  font-size:2rem;
  color: #666;
  padding: 2vw;
  border: 1px solid lightcoral;
  background-color: lightcoral;
}
.one-number:active {
  color: black;
  background-color: coral;
}
</style>