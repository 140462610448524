<template>
  <div class="bodies">
    <div class="prev">
      <van-icon
        name="arrow-left"
        color="#1989fa"
        size="3rem"
        @click="goPrev"
        v-if="currentSeq > 0"
      />
    </div>
    <div
      class="left"
      v-if="currentGene.calcNumbers && currentGene.calcNumbers.length > 0"
    >
      <template
        v-for="(oneCalcNumber, index) in currentGene.calcNumbers"
        :key="index"
      >
        <div class="number">
          {{ oneCalcNumber }}
        </div>
        <div class="symbol" v-if="currentGene.calcSymbols[index]">
          {{ currentGene.calcSymbols[index] === "add" ? "+" : "-" }}
        </div>
      </template>
    </div>
    <div class="equal">=</div>
    <div class="right">
      <div class="number result">
        {{ answer }}
      </div>
      <div class="delete-button" @click="resetAnswer">
        <van-icon name="revoke" />
      </div>
    </div>
    <div class="next" @click="goNext">
      <van-icon name="arrow" color="#1989fa" size="3rem" />
    </div>
  </div>

  <van-popup
    :style="{ padding: '10vh 10vw' , fontSize: '2.5rem', color:'orange' }"
    v-model:show="showSuccess"
    round
    closeable
    close-on-click-overlay
    @close="goNext"
    >答对了，下一题</van-popup
  >
  <van-popup
    :style="{ padding: '10vh 10vw',  fontSize: '3rem', color:'red' }"
    v-model:show="showError"
    round
    closeable
    >还没答对呢</van-popup
  >
</template>

<script>
import { ref, computed, watch } from "vue";
import { useStore } from "vuex";
export default {
  name: "VBody",
  setup() {
    const showSuccess = ref(false);
    const showError = ref(false);

    const store = useStore();
    const types = computed(() => store.state.types);
    const numbers = computed(() => store.state.numbers);
    const currentSeq = computed(() => store.state.currentSeq);
    const generated = computed(() => store.state.generated);
    const answer = computed(() => store.state.answer);
    const currentGene = computed(
      () =>
        generated.value[
          generated.value.findIndex((oneGene) => {
            return (oneGene.seq = currentSeq);
          })
        ]
    );

    const makeCalc = ([types, numbers]) => {
      const tempCalcNumbers = [];
      const tempCalcSymbols = [];
      for (let index = 0; index < parseInt(numbers); index++) {
        const oneCalcNumber = Math.floor(Math.random() * 21);
        tempCalcNumbers.push(oneCalcNumber);
      }
      for (let index = 0; index < parseInt(numbers) - 1; index++) {
        const oneCalcSymbols = types[Math.floor(Math.random() * types.length)];
        tempCalcSymbols.push(oneCalcSymbols);
      }
      console.log({ tempCalcNumbers, tempCalcSymbols });

      let result = tempCalcNumbers[0];
      for (let i = 1; i < tempCalcNumbers.length; i++) {
        const currentNumber = tempCalcNumbers[i];
        const symbols = tempCalcSymbols[i - 1];
        if (symbols == "add") {
          result = result + currentNumber;
        }
        if (symbols == "minus") {
          result = result - currentNumber;
        }
      }
      if (result < 0 || result > 20) {
        makeCalc([types, numbers]);
      } else {
        const added = {
          seq: currentSeq.value,
          calcNumbers: tempCalcNumbers,
          calcSymbols: tempCalcSymbols,
          calcResult: result,
        };
        const gene = [...generated.value];
        const existedIndex = gene.findIndex((oneGene) => {
          return oneGene.seq === currentSeq.value;
        });
        if (existedIndex >= 0) {
          gene.splice(existedIndex, 1, added);
        } else {
          gene.push(added);
        }
        store.commit("setGenerated", gene);
        resetAnswer()
      }
    };

    const resetGene = ([types, numbers]) => {
      store.commit("setCurrentSeq", 0);
      store.commit("setGenerated", []);
      makeCalc([types, numbers]);
    };

    const goNext = () => {
      if (checkAnswer(answer.value)) {
        showError.value = false;
        store.commit("setCurrentSeq", currentSeq.value + 1);
        makeCalc([types.value, numbers.value]);
      } else {
        showError.value = true;
      }
    };

    const goPrev = () => {
      store.commit("setCurrentSeq", currentSeq.value - 1);
    }

    const success = (newAnswer) => {
      if (checkAnswer(newAnswer)) {
        showSuccess.value = true;
        store.dispatch('addScore', 10)
        setTimeout(() => {
          showSuccess.value = false;
        }, 2000);
      } else {
        showSuccess.value = false;
      }
    };

    const checkAnswer = (newAnswer) => {
      return newAnswer && parseInt(newAnswer) === currentGene.value.calcResult;
    };

    const resetAnswer = () => {
      store.commit("setAnswer", "0");
    };

    watch([types, numbers], (newValues) => resetGene(newValues), {
      deep: true,
      immediate: true,
    });

    watch(answer, (newAnswer) => success(newAnswer));

    return {
      showSuccess,
      showError,
      checkAnswer,
      types,
      numbers,
      currentSeq,
      currentGene,
      answer,
      goNext,
      goPrev,
      resetAnswer,
    };
  },
};
</script>

<style  scoped>
.bodies {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  font-size: 3rem;
}
.left {
  flex-basis: 50%;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}
.number {
  padding: 2vw;
  border: 1px solid orangered;
}
.equal {
  flex-basis: 5%;
}
.right {
  flex-basis: 25%;
  display: flex;
  align-items: center;
}
.next,
.prev {
  background-color: #eee;
  flex-basis: 5%;
}

.result {
  flex-basis: 90%;
}
.delete-button {
  flex-basis: 10%;
  font-size: 2rem;
}
.delete-button :hover {
  color: gray;
}
.delete-button :active {
  color: red;
}
</style>