<template>
  <div class="headers">
    <div class="types">
      <span>用什么符号：</span>
      <van-checkbox-group v-model="types" direction="horizontal" @change="setTypes">
        <van-checkbox name="add">+</van-checkbox>
        <van-checkbox name="minus">-</van-checkbox>
      </van-checkbox-group>
    </div>
    <div class="difficulty">
      <span>算几个数字：</span>
      <van-radio-group v-model="numbers" direction="horizontal"  @change="setNumbers">
        <van-radio name="2" checked-color="#ee0a24">2</van-radio>
        <van-radio name="3" checked-color="#ee0a24">3</van-radio>
        <van-radio name="4" checked-color="#ee0a24">4</van-radio>
      </van-radio-group>
    </div>
    <div class="score">
      {{'得分：' + score}}
    </div>
  </div>
</template>

<script>
import { ref,onMounted, computed } from "vue";
import { useStore } from 'vuex'
export default {
    name:"VHeader",
  setup() {
    const store = useStore()
    const types = ref(["add"]);
    const numbers = ref("2");
    const score = computed(() => store.state.score)

    const setTypes = () => store.commit('setTypes', types.value)
    const setNumbers = ()=> store.commit('setNumbers', numbers.value) 
    onMounted(() => {
     setTypes(); setNumbers()
    })
    return { score, types, numbers, setTypes , setNumbers};
  },
};
</script>

<style  scoped>
.headers {
  height: 100%;;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}
.types, .difficulty {
  display: inline-flex;
  font-size: 1.2rem;
}
.score {
  color: plum;
  font-size: 1.2rem;
}
</style>