<template>
  <div class="home">
    <div class="header">
      <v-header></v-header>
    </div>
    <div class="body">
      <v-body></v-body>
    </div>
    <div class="footer">
      <v-footer></v-footer>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import VHeader from "../components/VHeader.vue"
import VBody from "../components/VBody.vue"
import VFooter from "../components/VFooter.vue"
import { mapState } from 'vuex'
export default {
  name: 'Home',
  components: {
    VHeader,VBody,VFooter
  },
  computed: {
    ...mapState({
      score: state => state.score,
      types: state => state.types,
      numbers: state => state.numbers
    })
  }
}
</script>

<style scoped>
.home {
  height: 100vh;
  width: 100vw;
  
}
.header {
  height: 10vh;
  border-bottom: solid #eee 1px;
}
.body {
  height: 50vh;
  border-bottom: solid #eee 1px;
}
.footer {
  height: calc(40vh - 2px);
}
</style>
